import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../Global/Theme';
import PricingCard from './PricingCard';
import logo from '../Prometheus/Images/logo.png';
import Buttons from '../Global/Buttons';
import Modal from '../Global/Modal';

const Wrapper = styled.div`
	position: relative;
	background-color: ${Theme.colors.white};
	padding: 1rem 5rem;
`;

const PageTitle = styled.h1`
	font-size: 3.4rem;
	color: ${Theme.colors.primary};
  text-align: center;
	line-height: 0;
`;

const PricingWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
	align-items: flex-end;
`;

const Banner = styled.div`
	box-sizing: border-box;
	width: 100vw;
	position: fixed;
	top: 64px;
	left: 0;
	color: white;
	background-color: ${Theme.colors.primary};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	z-index: 100;
`;

const SecondaryBannerButton = styled(Buttons.Primary)`
	margin-right: 10px;
	margin-left: 20px;
	border: 3px solid ${Theme.colors.white};
	box-shadow: none;
`;

const PrimaryBannerButton = styled(Buttons.Secondary)`
	margin: 0 20px;
	border: 3px solid ${Theme.colors.white};
	box-shadow: none;
`;

const LogoImage = styled.img``;

const FlexBar = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

const Input = styled.input`
	padding: 5px;
  border: 1px solid ${Theme.colors.grey};
  font-size: 1em;
  border-radius: 5px;
  color: ${Theme.font.color};
  font-family: ${Theme.font.default};

  &:focus {
    border: 1px solid ${Theme.colors.primary};
  }
`;

export default function Default() {
	const [rateEstimatorOpen, setRateEstimatorOpen] = useState(false);

	const [gbPerMonthRateEstimator, setGbPerMonthRateEstimator] = useState(0);
	const [hoursPerMonthRateEstimator, setHoursPerMonthRateEstimator] = useState(0);

	const [gbPerMonthCustomPriceCard, setGbPerMonthCustomPriceCard] = useState();
	const [hoursPerMonthCustomPriceCard, setHoursPerMonthCustomPriceCard] = useState();

	const [estimatedPriceRateEstimator, setEstimatedPriceRateEstimator] = useState(0);
	const [estimatedPriceCustomPriceCard, setEstimatedPriceCustomPriceCard] = useState(0);

	const roundNumToNearest = (num, nearest) => Math.round(num / nearest) * nearest;

	useEffect(() => {
		if (gbPerMonthRateEstimator > 0 && hoursPerMonthRateEstimator > 0) {
			setEstimatedPriceRateEstimator(roundNumToNearest(gbPerMonthRateEstimator * .25 + hoursPerMonthRateEstimator * 20, 1));
		}
	}, [gbPerMonthRateEstimator, hoursPerMonthRateEstimator]);

	useEffect(() => {
		let gbPerMonth = gbPerMonthCustomPriceCard;
		let hoursPerMonth = hoursPerMonthCustomPriceCard;

		if (!Number(gbPerMonth)) {
			gbPerMonth = 0;
		}
		if (!Number(hoursPerMonth)) {
			hoursPerMonth = 0;
		}

		if (gbPerMonth > 0 && hoursPerMonth > 0) {
			setEstimatedPriceCustomPriceCard(roundNumToNearest(gbPerMonth * .25 + hoursPerMonth * 20, 1));
		} else {
			setEstimatedPriceCustomPriceCard(0)
		}
	}, [gbPerMonthCustomPriceCard, hoursPerMonthCustomPriceCard]);

	useEffect(() => {
		setGbPerMonthRateEstimator(0);
		setHoursPerMonthRateEstimator(0)
	}, [rateEstimatorOpen]);

	const checkIfNumberIsValid = (number, setNumber) => {
		const value = number;
		if (value > 0 || typeof value != Number) {
			setNumber(number);
		}
	}
	return (
		<>
			<Modal display={rateEstimatorOpen} header="Rate Estimator">
				<b>Enter Gb/Month</b>
				<Input type="number" value={Number(gbPerMonthRateEstimator)} onChange={(event) => { checkIfNumberIsValid(event.target.valueAsNumber, setGbPerMonthRateEstimator); }} />
				<br />
				<b>Enter Hours/Month</b>
				<Input type="number" value={Number(hoursPerMonthRateEstimator)} onChange={(event) => { checkIfNumberIsValid(event.target.valueAsNumber, setHoursPerMonthRateEstimator); }} />

				<br />
				<div><b>Estimated Price:</b> ${estimatedPriceRateEstimator || 0}/Month</div>
				<br />
				<div className="modal-footer">
					<Buttons.Primary
						data-global-modals-modal-type-param="add_to_library"
						data-action="click->global-modals#closeModal"
						onClick={() => { setRateEstimatorOpen(false); }}
					>
						Close
					</Buttons.Primary>
				</div>
			</Modal>
			<Banner>
				<a href="https://app.prometheus.ms/" title="Go to Prometheus">
					<LogoImage src={logo} alt="Prometheus Logo" height="35" />
				</a>
				<FlexBar>
					<div style={{ alignContent: "default" }}>
						Flat pricing: $0.25/Gb and $20/usage hour.
					</div>
					<PrimaryBannerButton onClick={() => { setRateEstimatorOpen(true); }}>
						Open Rate Estimator
					</PrimaryBannerButton>
				</FlexBar>
			</Banner>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<PageTitle>Pricing</PageTitle>
			<Wrapper id="pricing">
				<PricingWrapper>
					<PricingCard title="Small" price="$1012.50/Month (USD)" whatsIncludedItems={['50 Gb/Month Data storage', '50 Hours/Month Use time', 'Unlimited Number of workstations', 'Unlimited Number of users', 'All Product features']} />
					<PricingCard title="Medium" price="$2025.00/Month (USD)" whatsIncludedItems={['100 Gb/Month Data storage', '100 Hours/Month Use time', 'Unlimited Number of workstations', 'Unlimited Number of users', 'All Product features']} />
					<PricingCard title="Large" price="$4050.00/Month (USD)" whatsIncludedItems={['200 Gb/Month Data storage', '200 Hours/Month Use time', 'Unlimited Number of workstations', 'Unlimited Number of users', 'All Product features']} isMostPopular />
					<PricingCard title="Custom" price={estimatedPriceCustomPriceCard <= 0 ? 'Varies' : `$${estimatedPriceCustomPriceCard}.00/Month (USD)`} whatsIncludedItems={
						[
							<>
								<Input
									style={{ width: "56px", marginRight: '10px' }}
									placeholder='Custom'
									type="number"
									value={Number(gbPerMonthCustomPriceCard)}
									onChange={(event) => { checkIfNumberIsValid(event.target.valueAsNumber, setGbPerMonthCustomPriceCard); }}
								/>
								Gb/Month Data storage
							</>,
							<>
								<Input
									style={{ width: "56px", marginRight: '10px' }}
									placeholder='Custom'
									type="number"
									value={Number(hoursPerMonthCustomPriceCard)}
									onChange={(event) => { checkIfNumberIsValid(event.target.valueAsNumber, setHoursPerMonthCustomPriceCard); }}
								/>
								Hours/Month Use time
							</>,
							'Unlimited Number of workstations',
							'Unlimited Number of users',
							'All Product features'
						]
					} />
				</PricingWrapper>
			</Wrapper>
			<br />
		</>
	);
}
