import { ReactComponent as DividerAlignLeft } from '../Prometheus/Images/divider-left-align.svg';
import { ReactComponent as DividerAlignCenter } from '../Prometheus/Images/divider-center-align.svg';
import { ReactComponent as DividerAlignRight } from '../Prometheus/Images/divider-right-align.svg';
import styled from 'styled-components';

const DividerAlignLeftReStyled = styled(DividerAlignLeft)`
  width: ${(props) => props.turnOffWidth ? '' : '100%'};
  height: auto;
  margin: 0 auto;
`;

const DividerAlignCenterReStyled = styled(DividerAlignCenter)`
  width: ${(props) => props.turnOffWidth ? '100%' : ''};
  height: auto;
  margin: 0 auto;
`;

const DividerAlignRightReStyled = styled(DividerAlignRight)`
  width: ${(props) => props.turnOffWidth ? '100%' : ''};
  height: auto;
  margin: 0 auto;
`;

const StylizedDivider = ({ alignment }) => {
  switch (alignment) {
    case "left":
      return <DividerAlignLeftReStyled />;
    case "center":
      return <DividerAlignCenterReStyled />;
    case "right":
      return <DividerAlignRightReStyled />;
    default:
      return <DividerAlignLeftReStyled />;
  }
};

export default StylizedDivider;