import React from 'react';
import styled from 'styled-components';
import logo from './prime_labs_logo_no_slogan.svg';
import Theme from '../Global/Theme';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
	/* Default Nav Styles */
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: calc(4rem + 2px);
	z-index: 99;

	background: #FFFFFF99;
	color: ${Theme.colors.black};
	backdrop-filter: blur(0.5rem);
	z-index: 99;
	border-bottom: 4px solid ${Theme.colors.primary};

	position: fixed;
	top: 0;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	colo
`;

const LinkBtn = styled(Link)`
	margin: auto 0.5rem;
	padding: 0.5rem 1rem;
	background-color: inherit;
	color: ${Theme.colors.black};
	font-size: 0.9rem;
	text-transform: uppercase;
	border: none;
	border-radius: 1rem;
	transition: 0.25s all ease-in-out;
	cursor: pointer;
	text-decoration: none;
	&:hover {
		background-color: ${Theme.colors.primary};
		color: white;
	}
`;

const NavLogo = styled.img`
	margin-left: 0.5rem;
	padding: 0.25rem 1rem;
`;

const SmallText = styled.div`
	font-size: 0.8em;
	color: initial;
`;

export default function Default() {
	return (
		<Wrapper>
			<Link to="/">
				<Container>
					<NavLogo src={logo} alt="" height="40px" />
					<SmallText>Do what you want. Love what you do.<sup style={{ fontSize: '6px' }}>TM</sup></SmallText>
				</Container>
			</Link>
			<Container>
				<LinkBtn to="/">Home</LinkBtn>
				{/* If we ever add the home page back again... */}
				{/* <LinkBtn to="/prometheus">Prometheus</LinkBtn> */}
				<LinkBtn to="/pricing">Pricing</LinkBtn>
				<LinkBtn to="/about">Prime Labs</LinkBtn>
				<LinkBtn to="/careers">Careers</LinkBtn>
				<LinkBtn to="/contact">Contact</LinkBtn>
			</Container>
		</Wrapper>
	);
}
