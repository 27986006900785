import '../../styles/modals.scss';
// import Theme from './Theme';

export default function Modal(props) {
  return (
    <div style={{
      display: props.display ? 'flex' : 'none'
    }} className="modal-background">
      <div className="modal-box">
        <div className="modal-header">
          {props.header}
          <hr />
        </div>
        {props.children}
      </div>
    </div>
  );
}