import React from 'react';
import styled from 'styled-components';
import Theme from '../Global/Theme';
import logo from '../logos/logo-icon.svg';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${Theme.colors.white};
	color: black;
	border-top: 2px solid ${Theme.colors.primary};
`;
const NavLogo = styled.img`
	margin: auto 0.5rem;
	padding: 0.25rem 1rem;
`;
const Links = styled.div`
	display: flex;
	justify-content: space-between;
	width: 20%;
`;
const LinkGroup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Copyright = styled.div`
	margin: auto;
	font-size: 0.9rem;
`;

const FooterLink = styled(Link)`
	color: ${Theme.colors.black};
	line-height: 30px;
	:hover {
		color: ${Theme.colors.primary};
	}
`;

export default function Default() {
	const currentYear = new Date().getFullYear();
	return (
		<Wrapper>
			<br />
			<br />
			<Copyright>
				&copy; Copyright
				{' '}
				{currentYear}
				{' '}
				Prime Labs, All rights reserved.
			</Copyright>
			<br />
			<br />
		</Wrapper>
	);
}
