import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Theme from '../Global/Theme';
import Buttons from '../Global/Buttons';
import logo from './Images/logo.png';
import billing from './Images/billing.png';
import login from './Images/login.png';
import graphs from './Images/new_graphs.png';
import uploadModal from './Images/upload_modal.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prometheusLogo from './Images/prometheus_logo.svg';
import StylizedDivider from '../Global/StylizedDivider';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

// custom hook for getting previous value 
function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}

const Wrapper = styled.div`
	margin: 0 16rem;
`;

const Container = styled.div`
	display: flex;
	justify-content: start;
	flex-direction: column;
	margin: auto;
	text-align: start;
`;

const FlexBar = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

const FloatingIcon = styled(FontAwesomeIcon)`
	color: ${Theme.colors.white};
	padding: 10px;
	border-radius: 1000px;
	background: ${Theme.colors.primary};
	cursor: pointer;
	width: max-content;
	margin-left: auto;
`;

const FloatingCloseIcon = styled(FontAwesomeIcon)`
	color: ${Theme.colors.primary};
	cursor: pointer;
	position: absolute;
	top: 4px;
	right: 17px;
`;

const FlexDiv = styled(FlexBar)`
	margin-bottom: 3rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

const Bold = styled.span`
	font-weight: 700 !important;
`;

const TextGroup = styled.div`
	margin: 0 3rem;
`;

const CenterTextGroup = styled.div`
	text-align: center;
	margin: 0 20rem;
`;

const CenterGroup = styled.div`
	margin: 0 auto;
`;

const Image = styled.img`
	margin: auto;
	height: 20rem;
	width: 30rem;
	object-fit: cover;
	border-radius: 1em;
`;

const LogoImage = styled.img``;

const Banner = styled.div`
	box-sizing: border-box;
	width: 100vw;
	position: fixed;
	top: 64px;
	left: 0;
	color: white;
	background-color: ${Theme.colors.primary};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	z-index: 100;
`;

const SecondaryBannerButton = styled(Buttons.Primary)`
	margin-right: 10px;
	margin-left: 20px;
	border: 3px solid ${Theme.colors.white};
	box-shadow: none;
`;

const PrimaryBannerButton = styled(Buttons.Secondary)`
	margin: 0 20px;
	border: 3px solid ${Theme.colors.white};
	box-shadow: none;
`;

const TextContainer = styled.div`
	text-align: center;
	width: 58rem;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.2em;
`;

const EffectOfPrometheus = styled.div`
	display: flex;
	width: max-content;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	font-size: 1.1em;
	i {
		margin: 0 10px;
		color: ${Theme.colors.primary};
	}
	div.underline {
		border-bottom: 2px solid ${Theme.colors.primary};
	}
`;

const Space = styled.span`
	margin: 0 3px;
`;

const Header = styled.h2`
	font-size: 1.7em;
	margin-bottom: 0;
`;

const SecondaryHeader = styled.h3`
	font-size: 1.3em;
	line-height: 0em;
`;

const TertiaryHeader = styled.h4`
	font-size: 1em;
	margin-bottom: 5px;
`;

const MagicColumns = styled.div`
	display: flex;
	align-items: top;
	justify-content: center;
`;

const MagicColumn = styled.div`
	background: ${(props) => (props.background)};
	color: ${(props) => (props.color)};

	${(props) => props.background ? 'overflow: clip !important;' : ''}

	display: flex;
	flex-direction: column;
	margin: 10px;
	border-radius: 5px;
	overflow: visible;
	overflow-wrap: break-word !important;
	word-wrap: break-word;
	hyphens: auto;
	width: 35rem;
`;

const MagicList = styled.div`
	height: 100%;
	width: 20rem;
`;

const MagicListItem = styled.div`
	padding: 20px;
	padding-right: 30px;
	font-size: 1.2em;
	font-weight: ${Theme.font.weightBold};
	:hover {
		background-color: ${Theme.colors.primaryLight};
		/* color: ${Theme.font.color}; */
	}
`;

const MagicContent = styled.div``;

const MagicContentItem = styled.div`
	display: ${(props) => (props.default ? 'flex' : 'none')};
`;

const MagicContentItemList = styled.div`
	width: 40rem;
`;

const MagicContentItemListItem = styled.div`
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	::before {
		content: '.|..';
		font-size: 0.4em;
		background-color: ${Theme.colors.primary};
		border-radius: 100%;
		color: ${Theme.colors.primary};
		margin-right: 10px;
		user-select: none;
	}
`;

const List = styled.div`
	div::before {
		content: '.|..';
		font-size: 0.4em;
		background-color: ${Theme.colors.primary};
		border-radius: 100%;
		color: ${Theme.colors.primary};
		height: 7px;
		margin-top: 5px;
		width: 7px;
		margin-right: 10px;
		user-select: none;
		align-self: top;
	}
	div {
		margin-bottom: 5px;
		display: flex;
		align-items: top !important;
	}
`;

const HeaderDiv = styled(FlexDiv)`
	width: max-content;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
	flex-direction: column;
`;

const Hr = styled.div`
	border-bottom: 2px solid ${Theme.colors.primary};
	margin-top: 10px;x
`;

// const Divider = styled.div`
// 	border-bottom: 4px solid ${Theme.colors.primary};
// 	width: 100%;
// `;

const Hidden = styled.span`
	display: ${(props) => props.hidden ? 'none !important' : 'block !important'};
	margin-right: auto;
`;

const Input = styled.input`
	padding: 5px;
  border: 1px solid ${Theme.colors.primary};
  font-size: 1em;
  border-radius: 5px;
  color: ${Theme.font.color};
  font-family: ${Theme.font.default};
	max-width: 20rem;

  &:focus {
    border: 1px solid ${Theme.colors.primary};
  }
`;

const PieChart = styled(Pie)`
	max-width: 20rem;
	max-height: 23rem;
`;

const DatasetList = styled(List)`
	display: none;
	margin: 0 auto;
`;

const ImageLogo = styled.img`
	width: 30rem;
	display: block;
	/* margin-left: auto; */
	margin: auto;
	padding: 10px;
	/* margin-right: auto; */
`;

const Error = styled.div`
	padding: 5px;
	border: 2px solid ${Theme.colors.error};
	color: ${Theme.colors.error};
`;

const Alert = styled.div`
  padding: 10px;
	border-radius: 25px;
	border: 3px solid ${Theme.colors.grey};
	position: fixed;
	bottom: 55px;
	right: 5px;
	background: white;
	display: ${(props) => props.shown ? 'block' : 'none'};
`;

const ScrollableTable = styled.span`
	max-height: 230px;
	overflow-x: scroll;
	display: block;
	width: max-content;
`;

const PopUp = styled.div`
	display: ${(props) => props.display ? 'block' : 'none'};
	/* position: absolute; */
  width: max-content;
  right: 9px;
	background-color: white;
	padding: 5px;
	border-radius: 5px;
	top: -200px;
	padding: 10px;
  box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
	align-self:flex-end;
	margin-right: 12px;
	&::before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: 100%;
		right: 15px;
		border: 5px solid transparent;
		border-bottom: none;
		border-top-color: white;
		filter: drop-shadow(0 0.0625rem 0.0625rem rgba(0, 0, 0, .2));
	}
`;

const BackgroundSection = styled(Wrapper)`
	background-color: ${Theme.colors.primary};
	color: white;
	width: 100%;
	margin: 0;
`;

export default function Default() {
	const [currentHoveredMagicItem, setCurrentHoverMagicItem] = useState('');
	const [prevHoveredMagicItem, setPrevHoverMagicItem] = useState('default');
	const [featuresDisplayed, setFeaturesDisplayed] = useState(undefined);
	const [whatIsDisplayed, setWhatIsDisplayed] = useState(undefined);
	const [whatPrometheusDoesListButtonSeeMore, setWhatPrometheusDoesListButtonSeeMore] = useState('See More');
	const [whatPrometheusDoesTableButtonSeeMore, setWhatPrometheusDoesTableButtonSeeMore] = useState('See More');
	const [isSlidePopupOpen, setIsSlidePopupOpen] = useState(undefined);
	const [isScheduleDemoPopupOpen, setIsScheduleDemoPopupOpen] = useState(undefined);
	const [isScheduleDemoPopupOpened, setIsScheduleDemoPopupOpened] = useState(undefined);
	const [hoveredDataset, setHoveredDataset] = useState(null);
	const [currentEmail, setCurrentEmail] = useState('');
	const [error, setError] = useState('');
	const [showEmailSentAlert, setShowEmailSentAlert] = useState(undefined);
	const prevHoveredDataset = usePrevious(hoveredDataset);

	const beforeMassSpecPercentage = 57;
	const beforeConfigPercentage = 43;

	const afterMassSpecPercentage = 85;
	const afterConfigPercentage = 15;

	useEffect(() => {
		if (currentHoveredMagicItem !== '') {
			if (prevHoveredMagicItem !== '' && prevHoveredMagicItem != 'default') {
				document.getElementById(prevHoveredMagicItem).style.display = 'none';
			} else {
				Array.from(document.getElementsByClassName('magic-content-item')).forEach((element) => element.style.display = 'none');
			}
			document.getElementById(currentHoveredMagicItem).style.display = 'flex';
		}
	}, [currentHoveredMagicItem]);

	useEffect(() => {
		if (prevHoveredDataset) {
			document.getElementById(prevHoveredDataset).style.display = 'none';
		}
		if (hoveredDataset) {
			document.getElementById(hoveredDataset).style.display = 'block';
		}
	}, [hoveredDataset]);

	const toggleIsDisplayed = (setDisplay, display) => {
		display ? setDisplay(undefined) : setDisplay(true);
	};

	const toggleDisplayedText = (defaultText, secondaryText, setText, text) => {
		text == defaultText ? setText(secondaryText) : setText(defaultText);
	};

	const beforeData = {
		labels: [
			'Time Configuring',
			'Time Doing Mass Spec',
		],
		datasets: [
			{
				data: [
					beforeConfigPercentage,
					beforeMassSpecPercentage
				],
				backgroundColor: [
					'#e32636',
					Theme.colors.primary,
				],
				borderWidth: 0,
			},
		],
	};

	const afterData = {
		labels: [
			'Time Configuring',
			'Time Doing Mass Spec',
		],
		datasets: [
			{
				data: [
					afterConfigPercentage,
					afterMassSpecPercentage
				],
				backgroundColor: [
					'#e32636',
					Theme.colors.primary,
				],
				borderWidth: 0,
			},
		],
	};

	const chartHoverConfig = {
		id: 'chartHoverConfig',
		afterDatasetsDraw(chart) {
			const { data } = chart;

			chart.getActiveElements().forEach(async (active) => {
				const currentData = data.datasets[0].data[active.index];
				const currHoveredDataset = (currentData == beforeConfigPercentage || currentData == afterConfigPercentage ? 'config' : 'mass_spec') + '_dataset';
				setHoveredDataset(currHoveredDataset);
			});
		}
	};

	const chartOptions = {
		plugins: {
			legend: {
				labels: {
					usePointStyle: true,
				}
			},
			tooltip: {
				callbacks: {
					title: (tooltipItems, _data) => {
						return `${tooltipItems[0].raw}%`;
					},
					label: (tooltipItem, _data) => {
						return tooltipItem.label;
					},
				},

				backgroundColor: 'rgba(40,40,40,0.6)',

				titleFont: {
					family: Theme.font.default,
					size: 16,
					weight: '900',
				},
				titleColor: 'white',

				bodyFont: {
					family: Theme.font.default,
					size: 14,
				},
				bodyColor: 'white',

				displayColors: undefined,
				cornerRadius: 0,
				borderColor: Theme.colors.grey,
				borderWidth: 1,
			},
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setIsSlidePopupOpen(true);
		}, 2000);
	}, []);

	const sendPromotionEmail = async (emailType) => {
		if (currentEmail) {
			const headers = new Headers({
				'Content-Type': 'application/json;'
			});

			// const request = new Request(`http://localhost:1818/pub_routes/confirm_email?email=${currentEmail}&next_action=${emailType}`, {
			// Change to this when pushing to production:
			const request = new Request(`https://app.prometheus.ms/pub_routes/confirm_email?email=${currentEmail}&next_action=${emailType}`, {
				method: 'GET',
				headers: headers,
				credentials: 'include',
				mode: 'no-cors'
			});

			await fetch(request);

			setIsSlidePopupOpen(undefined);
			setIsScheduleDemoPopupOpen(undefined);
			setShowEmailSentAlert(true);

			setTimeout(async () => {
				document.getElementById('email_alert').classList.add('roll-out');
				await new Promise(resolve => setTimeout(resolve, 600));
				setShowEmailSentAlert(undefined);
				document.getElementById('email_alert').classList.remove('roll-out');
			}, 2000);
		} else {
			setError('Please enter an email.');
		}
	};

	const startScheduleDemoPopupTimeout = () => {
		setTimeout(() => {
			setIsSlidePopupOpen(undefined);
			setIsScheduleDemoPopupOpen(true);
		}, 60000);
	};

	const toggleCurrentPopup = () => {
		if (!isSlidePopupOpen || isScheduleDemoPopupOpen) {
			setIsSlidePopupOpen(true);
		} else if (isSlidePopupOpen) {
			setIsSlidePopupOpen(undefined);
		}

		if (isSlidePopupOpen && !isScheduleDemoPopupOpen && !isScheduleDemoPopupOpened) {
			startScheduleDemoPopupTimeout();
			setIsScheduleDemoPopupOpened(true);
		}

		if (isScheduleDemoPopupOpen) {
			setIsScheduleDemoPopupOpen(undefined);
			setIsSlidePopupOpen(undefined);
		}
	};

	return (
		<>
			<div>
				<div style={{ position: 'fixed', display: 'flex', flexDirection: 'column', width: 'max-content', right: '10px', bottom: '10px' }}>
					<div style={{ position: 'relative' }}>
						{/* displayed after 2 seconds */}
						<PopUp display={isSlidePopupOpen}>
							<TertiaryHeader style={{ marginRight: '14px' }}>Want to learn more about Prometheus?</TertiaryHeader>
							<div style={{ marginTop: '0 !important', marginBottom: '3px' }}>Enter your email to get a short slide deck:</div>
							{error.length > 0 && (
								<>
									<Error>{error}</Error>
									<br />
								</>
							)}
							<Input placeholder='Enter your email' onChange={(e) => setCurrentEmail(e.target.value)} />
							<br />
							<br />
							<FlexBar style={{ maxWidth: 'max-content' }}>
								<Buttons.Primary onClick={() => { sendPromotionEmail('send_slide_deck_email'); startScheduleDemoPopupTimeout(); }} style={{ marginRight: '10px' }}>Get Slide Deck</Buttons.Primary>
								<FloatingCloseIcon icon="xmark-circle" onClick={() => { setIsSlidePopupOpen(undefined); startScheduleDemoPopupTimeout(); }} />
							</FlexBar>
						</PopUp>
						{/* displayed after 1 minute */}
						<PopUp display={isScheduleDemoPopupOpen}>
							<TertiaryHeader style={{ marginRight: '14px' }}>Would you like to schedule a demo?</TertiaryHeader>
							<br />
							<FlexBar style={{ maxWidth: 'max-content' }}>
								<a href="https://app.prometheus.ms/register?register_type=schedule_demo"><Buttons.Primary style={{ marginRight: '10px' }}>Schedule demo</Buttons.Primary></a>
								<FloatingCloseIcon icon="xmark-circle" onClick={() => setIsScheduleDemoPopupOpen(undefined)} />
							</FlexBar>
						</PopUp>
					</div>
					<br />
					<FloatingIcon icon="info-circle" onClick={() => { toggleCurrentPopup(); }} />
				</div>
			</div>

			<Alert shown={showEmailSentAlert} id="email_alert">
				Email sent. Check your spam folder if you can't find it.
			</Alert>

			<Wrapper>
				<Banner>
					<a href="https://app.prometheus.ms/" title="Go to Prometheus">
						<LogoImage src={logo} alt="Prometheus Logo" height="35" />
					</a>
					<FlexBar>
						<div style={{ alignContent: "default" }}>
							From raw data to reports, in the cloud.<sup style={{ fontSize: '10px' }}>TM</sup>
						</div>
						<a href="https://app.prometheus.ms/register?register_type=schedule_demo">
							<PrimaryBannerButton>
								Schedule Demo
							</PrimaryBannerButton>
						</a>

						<a href="https://app.prometheus.ms/register?register_type=free_trial">
							<SecondaryBannerButton>
								Sign up for a Free Trial
							</SecondaryBannerButton>
						</a>
					</FlexBar>
				</Banner>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<ImageLogo src={prometheusLogo} />

				<br />
				<br />
				<HeaderDiv>
					Our Mission:
					<br />
					<br />
					<SecondaryHeader>Reduce the time you spend making reports</SecondaryHeader>
				</HeaderDiv>
				<FlexBar style={{ width: '60rem', marginRight: 'auto', marginLeft: 'auto' }}>
					<div>
						<h4 style={{ textAlign: "center" }}>Without Prometheus:</h4>
						<PieChart data={beforeData} options={chartOptions} plugins={[chartHoverConfig]} onMouseLeave={() => {
							setHoveredDataset(null);
						}} />
					</div>

					<FlexBar style={{ width: '15rem', position: 'relative' }}>
						<DatasetList style={{ background: 'white', zIndex: 10 }} id="config_dataset">
							<div>Creating/editing figures</div>
							<div>Entering values into spreadsheets or tables</div>
							<div>Copy/pasting between programs</div>
							<div>Drawing/modifying figure annotations</div>
							<div>Editing/checking report document</div>
							<div>Double checking for transcription errors</div>
						</DatasetList>
						<DatasetList style={{ background: 'white', zIndex: 10 }} id="mass_spec_dataset">
							<div>Setting up/running experiments</div>
							<div>Interrogating data</div>
							<div>Interpreting spectra</div>
							<div>Elucidating identity/sequence/structure</div>
						</DatasetList>
					</FlexBar>

					<div>
						<h4 style={{ textAlign: "center" }}>With Prometheus:</h4>
						<PieChart data={afterData} options={chartOptions} plugins={[chartHoverConfig]} onMouseLeave={() => {
							setHoveredDataset(null);
						}} />
					</div>
				</FlexBar>

				<Container>
					<br />
					<br />
					<br />
					<StylizedDivider alignment='center' />
					<br />
					<br />
					<br />
					<HeaderDiv>
						<Header>What is Prometheus?</Header>
					</HeaderDiv>
					<br />
					<CenterTextGroup>Prometheus is a fully integrated web application that provides you with intuitive, interactive tools that you can use to quickly and easily go from raw data to reports, in the cloud.<sup style={{ fontSize: '10px' }}>TM</sup></CenterTextGroup>

					<br />
					<CenterGroup>
						<Buttons.Primary onClick={() => {
							toggleIsDisplayed(setWhatIsDisplayed, whatIsDisplayed); toggleDisplayedText('See More', 'Hide', setWhatPrometheusDoesListButtonSeeMore, whatPrometheusDoesListButtonSeeMore);
						}}>{whatPrometheusDoesListButtonSeeMore}</Buttons.Primary>
					</CenterGroup>
					<br />
					<br />
					<Hidden hidden={!whatIsDisplayed}>
						<FlexDiv>
							<TextGroup>
								<h3>Up to 10x faster than your present process.</h3>
								<p>
									Fast data interrogation and fully-integrated reports.
									Custom tools to help
									{' '}
									<Bold>YOU</Bold>
									{' '}
									filter
									{' '}
									<Bold>YOUR</Bold>
									{' '}
									data, the way
									{' '}
									<Bold>YOU</Bold>
									{' '}
									choose, much faster than you are used to.
									Instantly explore intermediate results with intuitive visualization tools, fully customizable tables and interactive graphs.
									Quickly access and reuse previous results, build customized templates and libraries, and much more.
								</p>
							</TextGroup>
							<Image src={graphs} alt="" />
						</FlexDiv>
						<br />
						<FlexDiv>
							<Image src={uploadModal} alt="" />
							<TextGroup>
								<h3>Secure, instant, easy storage.</h3>
								<p>
									Immediately accessible, automatically expanding secure storage of raw data and any supporting data (COA, 3rd party information, etc.).
									Easy drag and drop file storage.
									Download any data, any time.
								</p>
							</TextGroup>
						</FlexDiv>
						<br />
						<TextContainer>
							Prometheus is designed with a simple goal: do what a computer does best in order to help you do what you do best—faster, easier, and more effectively than you could in any other way.
						</TextContainer>
						<br />
						<br />

						<EffectOfPrometheus>
							<div className="underline">Less</div>
							<Space />
							hassle
							<i className="fa-solid fa-arrow-right" />
							<div className="underline">More</div>
							<Space />
							chemistry.
						</EffectOfPrometheus>

						<EffectOfPrometheus>
							<div className="underline">More</div>
							<Space />
							chemistry
							<i className="fa-solid fa-arrow-right" />
							<div className="underline">More</div>
							<Space />
							joy.
						</EffectOfPrometheus>

						<br />
						<br />

						<TextContainer>
							With Prometheus, you can spend <b>much</b> more of your time solving problems in mass spectrometry instead of wrestling with bad software.
						</TextContainer>
						<br />
						<br />
					</Hidden>
					<StylizedDivider alignment='center' />
					<br />
					<br />

					<FlexDiv>
						<TextGroup>
							<Header>Who is Prometheus for?</Header>
							<p>
								The current version of Prometheus is best suited<br />for investigators who use small molecule LC/MS and<br />GC/MS who create a report of their results.
							</p>
							<br />
							<br />
							<SecondaryHeader>Supported vendor data types</SecondaryHeader>
							<table>
								<thead>
									<tr>
										<th>Vendor</th>
										<th>File Type</th>
										<th>Supported</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th>Thermo</th>
										<th><code>.raw</code></th>
										<th>Supported</th>
									</tr>
									<tr>
										<th>Agilent</th>
										<th><code>.d</code></th>
										<th>Supported</th>
									</tr>
									<tr>
										<th>Bruker</th>
										<th><code>.d</code></th>
										<th>Supported</th>
									</tr>
									<tr>
										<th>AB SCIEX</th>
										<th><code>.wiff</code></th>
										<th>Not Yet</th>
									</tr>
									<tr>
										<th></th>
										<th><code>.mzML</code></th>
										<th>Supported</th>
									</tr>
								</tbody>
							</table>
							<br />
						</TextGroup>
						<Image src="https://images.unsplash.com/photo-1676545228858-b4d9de95704b?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
					</FlexDiv>

					<br />
					<br />
					<StylizedDivider alignment='center' />
					<br />
					<br />
					<br />

					<FlexDiv>
						<Image src="https://images.unsplash.com/photo-1656331797721-b593b8f00297?auto=format&fit=crop&q=80&w=3280&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
						<TextGroup>
							<Header>What does Prometheus do?</Header>
							<p>
								The current version of Prometheus provides tools for:
							</p>
							<List>
								<table>
									<thead>
										<tr>
											<th>Feature</th>
											<th>Included</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th>Small molecule characterization</th>
											<th>Yes</th>
										</tr>
										<tr>
											<th>Run and group content comparison</th>
											<th>Yes</th>
										</tr>
										<tr>
											<th>Relative quantification via area under the curve or<br />spectral peak height</th>
											<th>Yes</th>
										</tr>
										<tr>
											<th>Identification through MS1, MS2, or MS3 mass search of<br />custom libraries or prior results</th>
											<th>Yes</th>
										</tr>
									</tbody>
								</table>
								<br />
								<Buttons.Primary onClick={() => {
									toggleIsDisplayed(setFeaturesDisplayed, featuresDisplayed); toggleDisplayedText('See More', 'Hide', setWhatPrometheusDoesTableButtonSeeMore, whatPrometheusDoesTableButtonSeeMore);
								}}>{whatPrometheusDoesTableButtonSeeMore}</Buttons.Primary>
								<br />
								<br />
								<Hidden hidden={!featuresDisplayed}>
									<ScrollableTable>
										<table>
											<tbody>
												<tr>
													<th>Fully integrated automatic figure import and update in report</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Fully integrated editable report</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Build and edit custom libraries (MS1, MS2, MS3)</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Library searches by diagnostic ion mass</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Elemental composition by mass</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Elucidate ion type via co-eluting compounds</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Instant access to any run in your account</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Import structure image</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Fast, easy, and intuitive click and drag run alignment</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Fast, easy, and intuitive manual peak detection</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Unlimited, instantly-expandable secure data storage</th>
													<th>Yes</th>
												</tr>
												<tr>
													<th>Algorithmic spectral matching</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>Automatic run alignment</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>Automatic peak detection</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>Calibration curves (DMPK)</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>Fragmentation prediction</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>Peptide maps</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>Disulfide bridge mapping</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>Shotgun proteomics</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>MS imaging</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>Ion mobility MS</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>DNA/RNA</th>
													<th>Not Yet</th>
												</tr>
												<tr>
													<th>NMR</th>
													<th>Not Yet</th>
												</tr>
											</tbody>
										</table>
									</ScrollableTable>
									<br />
								</Hidden>
							</List>
						</TextGroup>
					</FlexDiv>

					<br />
					<br />
					<StylizedDivider alignment='center' />
					<br />
					<br />
					<br />
					<FlexDiv>
						<TextGroup>
							<Header>Easy to try, easy to learn.</Header>
							<ol>
								<li>Open a web browser.</li>
								<br />
								<li>Interactively explore your data.</li>
								<br />
								<li>Report your findings.</li>
							</ol>
							<br />
							<a href="https://app.prometheus.ms/register?register_type=schedule_demo"><Buttons.Primary>Schedule Demo</Buttons.Primary></a>
						</TextGroup>
						<Image src={login} alt="" />
					</FlexDiv>
					<br />
					<StylizedDivider alignment='center' />
					<br />
					<br />
					<br />
					<FlexDiv>
						<Image src={billing} alt="" />
						<TextGroup>
							<Header>All features, one product, convenient and fair pricing</Header>
							<br />
							<List>
								<div>You can try out and adopt Prometheus without the time or money other software requires.</div>
								<div>No need for large financial commitment up front. You can buy annual plans<br />for Prometheus, but you don't have to. You can sign up for as little as one month, and you<br />can adjust your plan anytime.</div>
								<div>No need to purchase multiple programs. One piece of software with full access to all features.</div>
								<div>No modules to parse through. It's all included in our only version.</div>
								<div>No need to navigate license structures, number of seats, etc. Everyone on your<br />team can use the software for the same price per GB and per hour of use.</div>
							</List>
						</TextGroup>
					</FlexDiv>

					<br />
					<br />
					<StylizedDivider alignment='center' />
					<br />
					<br />
					<br />

					<HeaderDiv>
						<Header>The Magic:</Header>
					</HeaderDiv>
					<br />

					<MagicColumns>
						<MagicColumn style={{ marginLeft: '300px' }} background={Theme.colors.primaryDark} color="white" onMouseLeave={() => { setPrevHoverMagicItem(''); setCurrentHoverMagicItem('intuitive'); }}>
							<MagicList>
								<MagicListItem onMouseEnter={() => setCurrentHoverMagicItem('intuitive')} onMouseLeave={() => { setPrevHoverMagicItem('intuitive'); setCurrentHoverMagicItem(''); }}>
									Intuitive
								</MagicListItem>
								<MagicListItem onMouseEnter={() => setCurrentHoverMagicItem('instant')} onMouseLeave={() => { setPrevHoverMagicItem('instant'); setCurrentHoverMagicItem(''); }}>
									Instant
								</MagicListItem>
								<MagicListItem onMouseEnter={() => setCurrentHoverMagicItem('interactive')} onMouseLeave={() => { setPrevHoverMagicItem('interactive'); setCurrentHoverMagicItem(''); }}>
									Interactive
								</MagicListItem>
								<MagicListItem onMouseEnter={() => setCurrentHoverMagicItem('integrated')} onMouseLeave={() => { setPrevHoverMagicItem('integrated'); setCurrentHoverMagicItem(''); }}>
									Integrated
								</MagicListItem>
								<MagicListItem onMouseEnter={() => setCurrentHoverMagicItem('intentional')} onMouseLeave={() => { setPrevHoverMagicItem('intentional'); setCurrentHoverMagicItem(''); }}>
									Intentional
								</MagicListItem>
							</MagicList>
						</MagicColumn>
						<MagicColumn border={Theme.colors.primary}>
							<MagicContent>
								<MagicContentItem default className='magic-content-item' id="intuitive">
									<MagicContentItemList>
										<MagicContentItemListItem>
											Irresistibly intuitive.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											So easy to use, you&apos;ll get the hang of it the first time you use it.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											No need to guess what anything does. Embedded documentation on every page.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											No need to learn multiple programs. One piece of software with uniform look and feel.
										</MagicContentItemListItem>
									</MagicContentItemList>
								</MagicContentItem>

								<MagicContentItem className='magic-content-item' id="instant">
									<MagicContentItemList>
										<MagicContentItemListItem>
											Browser based. Log on, and you&apos;re set.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											No need to navigate multiple programs. One piece of software with full access to all features.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Truly cloud based. No software to install, no hardware to configure.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Fully managed. You never have to worry about hardware, servers, etc.
										</MagicContentItemListItem>
									</MagicContentItemList>
								</MagicContentItem>

								<MagicContentItem className='magic-content-item' id="interactive">
									<MagicContentItemList>
										<MagicContentItemListItem>
											Sample characterization from interactive graphs, library searches, and formula generation.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Use custom graphs to interactively interrogate any data, any time.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Fully navigable table, graph, and report views.
										</MagicContentItemListItem>
									</MagicContentItemList>
								</MagicContentItem>

								<MagicContentItem className='magic-content-item' id="integrated">
									<MagicContentItemList>
										<MagicContentItemListItem>
											Figures and tables automatically import from experiment data into report.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Report automatically updates after any changes to data.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Automatic reference tracking, figure and table updating, and much more.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Create and edit features across runs, groups, or between groups.
										</MagicContentItemListItem>
									</MagicContentItemList>
								</MagicContentItem>

								<MagicContentItem className='magic-content-item' id="intentional">
									<MagicContentItemList>
										<MagicContentItemListItem>
											Annotate chromatograms at the run, group, or between group levels.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Many time-saving tools.
										</MagicContentItemListItem>
										<MagicContentItemListItem>
											Single-click navigation between report figures and data sources.
										</MagicContentItemListItem>
									</MagicContentItemList>
								</MagicContentItem>
							</MagicContent>
						</MagicColumn>
					</MagicColumns>

					<br />
					<br />
					<br />
					<br />
					<br />
				</Container>
			</Wrapper>
			<BackgroundSection>
				<br />
				<br />
				<br />
				<br />
				<br />
				<HeaderDiv>
					<Header>Why should you believe us?</Header>
				</HeaderDiv>
				<br />

				<TextContainer>
					You shouldn&apos;t! Try it for yourself.
					<br />
					<br />
					<a href="https://app.prometheus.ms/register?register_type=schedule_demo"><PrimaryBannerButton>Schedule Demo</PrimaryBannerButton></a>
				</TextContainer>

				<br />
				<br />
				<br />
				<br />
				<br />
			</BackgroundSection>
		</>
	);
}
