import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import Buttons from '../Global/Buttons';
import Theme from '../Global/Theme';
import { Link } from 'react-router-dom';

const PricingCard = styled.div`
  background: #F3F3F3;
  border: 4px solid ${(props) => (props.isMostPopular ? Theme.colors.primary : Theme.colors.grey)};
  border-radius: 7px;
  margin-bottom: 40px;
	transition: 0.15s;
  min-height: ${(props) => (props.isMostPopular ? '430px' : '390px')};
	:hover {
		border: 4px solid ${Theme.colors.primary};
		transform: scale(1.01);
		box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	}
`;

const PricingHeaderWrapper = styled.div`
  background-color: ${(props) => (props.isMostPopular ? Theme.colors.primary : Theme.colors.greyLight)};
  color: ${(props) => (props.isMostPopular ? Theme.colors.white : Theme.colors.black)};
  border-radius: ${(props) => (props.isMostPopular ? '0px' : '7px 7px 0px 0px')};
  padding: 10px;
`;

const PricingHeader = styled.h1`
  font-weight: 600;
  font-size: 38px;
  line-height: 0px;
  color: ${(props) => (props.isMostPopular ? Theme.colors.white : Theme.colors.primary)};
`;

const PricingCardPrice = styled.h4`
  font-weight: 500;
  font-size: 15px;
`;

const PricingItalic = styled.i`
  font-size: 0.5em;
`;

const WhatsIncludedWrapper = styled.div`
  margin-top: 10px;
  text-align: left;
`;

const WhatsIncluded = styled.div`
  padding: 1px 10px;
`;

const WhatsIncludedItem = styled.div`
  line-height: 1px;
  font-size: 0.8em;
  text-transform: uppercase;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
	margin-bottom: 20px;
`;

const CheckIconWrapper = styled.div`
  color: ${Theme.colors.primary};
  margin: 0 5px;
`;

const NewLine = styled.br`
`;
export default function Default(props) {
	const {
		whatsIncludedItems, title, price, isMostPopular
	} = props;
	return (
		<PricingCard isMostPopular={isMostPopular}>
			<PricingHeaderWrapper isMostPopular={isMostPopular}>
				{isMostPopular && (
					<>
						<b>MOST POPULAR</b>
						<hr />
					</>
				)}
				<br />
				<PricingHeader isMostPopular={isMostPopular}>
					{title}
				</PricingHeader>
				<PricingCardPrice>
					{price}
				</PricingCardPrice>
				<a href="https://app.prometheus.ms/register?register_type=free_trial">
					<Buttons.Primary reversed={isMostPopular}>
						Start My
						{' '}
						<b>FREE</b>
						{' '}
						trial
					</Buttons.Primary>
				</a>
				<NewLine />
				<NewLine />
				{/* <a to="#">
					<Buttons.Secondary reversed={isMostPopular}>
						Try now with
						{' '}
						<b>PRE-LOADED</b>
						{' '}
						sample data
					</Buttons.Secondary>
				</a>
				<NewLine />
				<NewLine />
				<a to="#">
					<Buttons.Secondary reversed={isMostPopular}>
						*View pre-recorded Webinar
					</Buttons.Secondary>
				</a>
				<NewLine />
				<PricingItalic>*No signup, no contact info</PricingItalic> */}
			</PricingHeaderWrapper>
			<WhatsIncludedWrapper>
				{whatsIncludedItems.map((item) => (
					<WhatsIncluded key={item}>
						<WhatsIncludedItem>
							<CheckIconWrapper>
								<FontAwesomeIcon icon="check" />
							</CheckIconWrapper>
							{item}
						</WhatsIncludedItem>
					</WhatsIncluded>
				))}
			</WhatsIncludedWrapper>
			<br />
		</PricingCard>
	);
}
