import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import styled from 'styled-components';

// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { prometheus } from './icons';

// UI
import Header from './Header';
// import Main from './Main';
import Prometheus from './Prometheus';
import Pricing from './Pricing';
import About from './PrimeLabs';
import Careers from './Careers';
import Contact from './Contact';
import Footer from './Footer';

// Styles
import '../styles/reset.scss';
import '../styles/fonts.scss';
import '../styles/tables.scss';
import StylizedDivider from './Global/StylizedDivider';

const Wrapper = styled.div`
	width: 100vw;
	height: 100%;
	min-height: 90vh;
`;

export default function Default() {
	library.add(fas);
	library.add(prometheus);
	return (
		<Router>
			<Wrapper>
				<Header />
				<Routes>
					<Route path="/" element={<Prometheus />} />
					{/* If we ever add the home page back again... */}
					{/* <Route path="/prometheus" element={<Prometheus />} /> */}
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/about" element={<About />} />
					<Route path="/careers" element={<Careers />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="*" element={<NotFoundError />} />
				</Routes>
				<div style={{ height: '100%' }}></div>
				<Footer />
			</Wrapper>
		</Router>
	);
}

const Container = styled.div`
	min-height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const Heading = styled.h1`
	font-size: 8em;
	margin-bottom: 20px;
`;

const Divider = styled(StylizedDivider)`
	width: auto !important;
`;

const NotFoundError = () => {
	return (
		<>
			<Container>
				<Heading>
					404
				</Heading>
				<Divider alignment="center" turnOffWidth="true" />
				<p>The page you requested was not found.</p>
				<Link to="/">Return to home</Link>
			</Container>
		</>
	);
}; 