const font = {
	default: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
	alt: '"Noto Sans", "Helvetica", "Arial", sans-serif',
	weightNormal: '400',
	weightBold: '500',
	sizeSmall: '12px',
	sizeMedium: '16px',
	sizeLarge: '20px',
	sizeXl: '24px',
	sizeXxl: '28px',
	color: 'rgba(47,58,76,1)',
};
const colors = {
	primary: '#31708E',
	primaryLight: '#8EB1C1',
	primaryDark: '#2D6682',
	secondary: '#a4c397',
	secondaryLight: '#b7d6a9',
	lightBlue: '#F9FDFF',
	grey: '#E1E1E1',
	greyLight: '#F7F7F7',
	greyMedium: '#A4A4A4',
	greyDark: '#555555',
	white: '#FFFFFF',
	whiteSmoke: '#F6F6F6',
	black: '#0f0f0f',
	success: '#3adb76',
	error: '#C20114',
	warning: '#ffae00',
	notice: '#8EB1C1',
	working: '#1396d7',
	active: '#89dff7',
	transparent: '#FFFFFF00',
	newFeatureOp: 'rgba(85, 111, 68, 0.5)',
	newFeatureLight: '#A9C19A',
	newFeature: '#87C5DD',
	missingFeature: '#e39dab',
	missingFeatureOp: 'rgba(165, 36, 61, 0.5)',
	missingFeatureLight: '#f08198',
	newFeatureAlt: '#48931f4D',
	missingFeatureAlt: '#c413034D'
};
const bp = {
	mobile: '360px',
	tablet: '680px',
	desktop: '1024px',
};
export default {
	font,
	colors,
	bp,
};
