import styled from 'styled-components';
import Theme from '../Theme';

const Primary = styled.button`
	display: inline-block;
	border: none;
	color: ${(props) => (props.reversed ? Theme.colors.primary : Theme.colors.white)};
	padding: 0.5rem 1rem;
	cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
	transition: 0.15s all ease-in-out;
	border-radius: 2rem;
	/* box-shadow: ${(props) => (props.disabled ? 'none' : '0 8px 6px -6px rgba(0,0,0,0.25)')}; */
	box-shadow: ${(props) => (props.disabled ? 'none' : 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px')};
	text-transform: uppercase;
	font-size: 0.7rem;
	letter-spacing: 1px;
	background-color: ${(props) => {
		if (props.disabled) {
			return Theme.colors.grey;
		}
		return props.reversed ? Theme.colors.white : Theme.colors.primary;
	}};
	margin-top: auto;
	margin-bottom: auto;
	&:hover {
		color: white;
		background-color: ${(props) => (props.disabled ? Theme.colors.grey : Theme.colors.primaryLight)};
		/* box-shadow: ${(props) => (props.disabled ? 'none' : '0 5px 6px -6px rgba(0,0,0,0.25)')}; */
		box-shadow: ${(props) => (props.disabled ? 'none' : 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px')};
	}
	max-width: 20em;
	font-weight: 700;
`;

export default Primary;
