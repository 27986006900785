import styled from 'styled-components';
import Theme from '../Theme';

const Secondary = styled.button`
	display: inline-block;
  color: ${(props) => (props.reversed ? Theme.colors.white : Theme.colors.primary)};
	padding: 0.5rem 1rem;
	cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
	transition: 0.15s all ease-in-out;
	border-radius: 2rem;
	text-transform: uppercase;
	font-size: 0.7rem;
	letter-spacing: 1px;
	border: 4px solid ${(props) => {
		if (props.disabled) {
			return Theme.colors.grey;
		}
		return props.reversed ? Theme.colors.white : Theme.colors.primary;
	}};
  background-color: ${(props) => (props.reversed ? Theme.colors.primary : null)};
	margin-top: auto;
	margin-bottom: auto;
	&:hover {
		background-color: ${(props) => (props.disabled ? Theme.colors.grey : Theme.colors.primaryLight)};
		color: ${(props) => (props.disabled ? Theme.colors.grey : Theme.colors.white)};
	}
	max-width: 20em;
	font-weight: 700;
`;

export default Secondary;
