import React from 'react';
import styled from 'styled-components';
import Theme from '../Global/Theme';
import Buttons from '../Global/Buttons';
import { Link } from 'react-router-dom';
import StylizedDivider from '../Global/StylizedDivider';

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100vw;
	height: 90vh;
`;

const Container = styled.div`
	display: flex;
	position: relative;
	flex-wrap: wrap;
	align-items: center;
	flex-grow: 1;
	flex-direction: column;
	margin: 0 10rem;
`;

const PageTitle = styled.h2`
	font-size: 3.4rem;
	color: ${Theme.colors.primary};
	margin-top: 0;
	margin-right: 5rem;
	margin-bottom: 5px;
`;

const ContentWrapper = styled.div`
	width: 100%;
`;

const Content = styled.p`
	line-height: 1.5rem;
	margin-bottom: 1.5rem;
	margin-right: 5rem;
	/* max-width: 50%; */
`;

const Button = styled(Buttons.Primary)`
	width: max-content;
`;

const FlexDiv = styled.div`
	display: flex;
	height: 100%;
	width: 100vw;
`;

const Image = styled.div`
	overflow: clip;
	width: 60%;
	height: auto;
	position: relative;
	margin-left: 50px;
	p {
		position: absolute;
		bottom: -7px;
    right: 164px;
		font-size: 10px;
		color: ${Theme.colors.greyMedium};
		a {
			color: ${Theme.colors.greyMedium};
			text-decoration: underline;
		}
	}

	div {
		/* background: red; */
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(0, 66px);
		background: rgb(0,0,0);
		background: linear-gradient(0deg, rgba(0, 0, 0) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);
	}
`;

const PrimeLabsWrapper = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
`;

const Divider = styled(StylizedDivider)`
	margin-right: 40px;
`;

export default function Default() {
	return (
		<Wrapper>
			<Container>
				<FlexDiv>
					<PrimeLabsWrapper>
						<PageTitle>Prime Labs</PageTitle>
						<Divider />
						<ContentWrapper>
							<Content>
								Prime Labs was founded to make software that empowers mass spectrometry scientists to do what they want to do and enjoy doing it. Our software is designed to enable a broader range of experiments in far less time with higher accuracy and more coverage.
								<br />
								<br />
								Achieving this goal requires intentional interfaces, cutting edge algorithms, and innovative infrastructure that enables us to do what no one else can. Our software wraps these advancements in an incredible intuitive, modern SaaS design that allows you to drop in and start enjoying this typically dreadful process without lengthy training times or onerous installations.
							</Content>
							<Link to="/">
								<Button>
									Check out Prometheus
								</Button>
							</Link>
						</ContentWrapper>
					</PrimeLabsWrapper>
					<Image>
						<img
							alt=""
							style={{
								objectFit: 'cover',
								width: '100%',
								height: '100%',
								transform: 'translate(0, 66px)'
							}}
							src="https://source.unsplash.com/UMJUr3st0AE/1000x1000"
						/>
						<div></div>
						<p>Not too far from our offices. Photo by <a href="https://unsplash.com/@togna_bologna" target="_blank" rel="noopener noreferrer">Tony Reid</a>.</p>
					</Image>
				</FlexDiv>
			</Container>
		</Wrapper>
	);
}
