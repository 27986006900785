import React from 'react';
import styled from 'styled-components';
import Theme from '../Global/Theme';

const Wrapper = styled.div`
	position: relative;
	text-align: center;
	margin: 0 10rem;
`;

const PageTitle = styled.h2`
	font-size: 3.4rem;
	color: ${Theme.colors.primary};
  text-align: center;
	line-height: 0;
`;

const ContentWrapper = styled.div`
	padding: 0 15rem;
`;

const Content = styled.p`
	line-height: 1.5rem;
	margin-bottom: 1.5rem;
`;

export default function Default() {
	return (
		<Wrapper>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<PageTitle>Careers</PageTitle>
			<ContentWrapper>
				<Content>
					Prime Labs is based outside of Missoula, MT. Our culture is about as different from a normal tech company as our location is. We like to think of it as working at a place you actually enjoy working at while living in a place you actually enjoy living in. What a concept!
				</Content>
				<Content>
					Our software is designed to help scientists do more while enjoying it more, and we&apos;re so serious about it that we like our company to work the same way: We do more than the norm, and we enjoy doing it more than the norm.
				</Content>
				<Content>
					Our mission is to do what others either haven&apos;t thought of or consider impossible. We do it every day, and we&apos;re good at it. Our developers do not live in the infinite loop of meaningless, repetitive, menial problems you will find in most tech jobs. If that makes you excited, rather than anxious, you might be a good fit.
				</Content>
				<Content>
					We come to work to make brilliant, beautiful, easy-to-use software in order to make our customers&apos; lives more enjoyable. While you will work with amazing people, Prime Labs is neither a political nor social club, and if you are looking for that sort of thing, please look elsewhere.
				</Content>
				<Content>
					Prime Labs is a place where we all learn and grow. We love people who are really good at what they do, and we can help you become that sort of person if you are still on your way, but no matter what your perceived or actual skill level, you’ll need to be the kind of person who loves getting constructive feedback, loves growing, and loves helping others grow.
				</Content>
				<Content>
					Prime Labs is an amazing place to grow, whether you are just starting out or have years of experience, but we don&apos;t pay new people based on what we hope they turn out to be, nor do we pay senior people based on how many years of experience they have. Prime Labs is a strict meritocracy. We care what you can do and whether you are easy to work with. We hire people, not positions. We pay people according to the proven value they add to the company. If all of this excites you, you might be a good fit.
				</Content>
				<Content>
					Finally — we are a little obsessed with how our software looks and feels. We place tremendous value in people who are good at making things beautiful and easy to use. In our experience, not many tech-oriented people have this skillset, and we value it highly. If this describes you, please reach out.
				</Content>
				<Content>
					Good people are hard to find, and we are always looking. If you think you might enjoy working at Prime Labs, please email a cover letter and resume to
					{' '}
					<a href="https://mail.google.com/mail/u/0/?fs=1&to=careers@primelabs.ms&tf=cm" target="_blank" rel="noreferrer">careers@primelabs.ms</a>
					. Note: At the present time, we are only considering candidates who can work on-location.
				</Content>
				<Content>
					Skills: Software development, UI design, testing, support, sales.
				</Content>
				<Content>
					Tech stack: Rails, Ruby, Javascript, PSQL, SQLite | AWS, docker.
				</Content>
			</ContentWrapper>
		</Wrapper>
	);
}
