import React from 'react';
import styled from 'styled-components';
import Theme from '../Global/Theme';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100vw;
	height: 90vh;
`;

const Container = styled.div`
	display: flex;
	position: relative;
	flex-wrap: wrap;
	flex-grow: 1;
	flex-direction: column;
  text-align: center;
`;

const PageTitle = styled.h2`
	font-size: 3.4rem;
	color: ${Theme.colors.primary};
  text-align: center;
	line-height: 0;
`;

export default function Default() {
	return (
		<Wrapper>
			<Container>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<PageTitle>Contact</PageTitle>
				<p>
					You can contact us
					{' '}
					<a href="https://mail.google.com/mail/u/0/?fs=1&to=contact@primelabs.ms&tf=cm" target="_blank" rel="noreferrer">here</a>
					{' '}
					or at contact@primelabs.ms.
				</p>
			</Container>
		</Wrapper>
	);
}
